import React from "react";
import CountUp from "react-countup";
function CountData() {
  return (
    <section class="main_counter_home">
      <div className="container-fluid bg_counterimg45 mt-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 d-flex flex-column align-items-center">
              <div className="count_1abcde">
                <img
                  className="circle_countttt45"
                  src="/assets/images/circ_2.png"
                />
                <h6 className="count_num788">
                  <CountUp end={200} enableScrollSpy={true}  />
                </h6>
              </div>
              <h5 className="cont_nameppp78">Products</h5>
            </div>
            <div className="col-lg-3 d-flex flex-column align-items-center">
              <div className="count_1abcde">
                <img
                  className="circle_countttt45"
                  src="/assets/images/circ_2.png"
                />
                <h6 className="count_num788">
                  {" "}
                  <CountUp end={150} enableScrollSpy={true} />
                </h6>
              </div>
              <h5 className="cont_nameppp78">Satisfied Clients</h5>
            </div>
            <div className="col-lg-3 d-flex flex-column align-items-center">
              <div className="count_1abcde">
                <img
                  className="circle_countttt45"
                  src="/assets/images/circ_3.png"
                />
                <h6 className="count_num788">
                  {" "}
                  <CountUp end={8}  enableScrollSpy={true} />
                </h6>
              </div>
              <h5 className="cont_nameppp78">Division</h5>
            </div>
            <div className="col-lg-3 d-flex flex-column align-items-center">
              <div className="count_1abcde">
                <img
                  className="circle_countttt45"
                  src="/assets/images/circ_4.png"
                />
                <h6 className="count_num788">
                  {" "}
                  <CountUp end={30} enableScrollSpy={true} />
                </h6>
              </div>
              <h5 className="cont_nameppp78">Employee</h5>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default CountData;
