import React, { useState, useEffect } from "react";
import Header from "../components/Header";
import AboutHead from "../components/AboutHead";
import VideoSlider from "../components/VideoSlider";
import Slider from "react-slick";
import Calculator from "../components/Calculator";
import { Link } from "react-router-dom";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import CountUp from "react-countup";
import ProgressProvider from "../components/ProgressProvider";
import FixButtons from "../components/FixButtons";
import CountData from "../components/CountData";
import Testimonial from "../components/Testimonial";
import Seo from "../components/Seo";

const HomePage = () => {
  const [activeIcon, setActiveIcon] = useState("Registered Trademarks");
  const [currentPage, setCurrentPage] = useState();
  // const [totalPage, setTotalPage] = useState();
  const [valueEnd, setValueEnd] = useState(300);
  const getContentForIcon = (icon) => {
    switch (icon) {
      case "Registered Trademarks":
        return " With their stakeholders, We because the best PCD Pharma companies";
      case "Registered Medicines":
        return " With their stakeholders, We because the best PCD Pharma companies";
      // Add more cases for other icons if needed
      default:
        return "Default content.";
    }
  };

  const handleIconClick = (icon) => {
    setActiveIcon(icon);
  };

  let settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    beforeChange: (currentSlide, nextSlide) => {
      // Handle the current page and total pages here
      // console.log(`Current Page: ${nextSlide + 1}`);
      setCurrentPage(nextSlide + 1);
      //setTotalPage(settings.slidesToShow);
      //console.log(`Total Pages: ${settings.slidesToShow}`);
    },
  };
  const settingsImg = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  useEffect(() => {
     window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Seo
        title="Pharma Franchise Company in India | PCD Pharma - Bioraiz Healthcare"
        description="If your are looking for Pharma Franchise Company in India? Bioraiz Healthcare is WHO-ISO certified Pharma Franchise Company in India. Contact us Now! 7289822446"
        type="webapp"
        name="Bioraiz Pharma"
      />
      <div className="page-content bg-white">
        {/* <a className="fx__textbtn" download href="/images/product-listr.pdf" target="_blank">Download Price List</a> */}
        <Link
          className="fx__textbtn anchor-hover-rj"
          to="/assets/images/product-listr.pdf"
          target="_blank"
          download
        >
          Download Price List
        </Link>
        {/* Main Banner */}
        <div
          className="main-banner"
          style={{ backgroundImage: "url(images/main-banner/bg1.jpg)" }}
        >
          <div className="container inner-content">
            <div className="row align-items-center">
              <div className="col-lg-6 col-md-6 col-sm-7">
                <h6 className="title-ext text-primary">
                  We Provide All Health Care Solution
                </h6>
                <h1 className="mainhead_bio">
                  Best Pharma Franchise Company in India: Bioraiz Healthcare!
                </h1>
                <p className="mainhead_para">
                  One of the top pharma Franchise company in India, Bioraiz
                  Healthcare is an ISO certified pharma PAN India healthcare
                  unit. We are an all-in-one <a href="https://www.bioraiz.in/pharma-franchise/">pharma franchise </a> unit for a wide
                  range of cutting-edge, reasonably priced, high-quality
                  medicines to meet the needs of every business be it
                  wholesalers, retailers or <a href="https://curasiamedilabs.com/top-10-pharma-distributors-in-india/">pharmaceutical distributors </a>.
                </p>

                <Link
                  to={"/contact-us"}
                  className="btn btn-secondary btn-lg shadow mt-2"
                >
                  Enquire Us
                </Link>
              </div>

              <div className="col-lg-6 col-md-6 col-sm-7 bg_posabcd">
                <div className=" sec_bgpinkff"></div>
                {/*slider*/}

                <Slider {...settings}>
                  <div className="carousel-item active" data-bs-interval="3000">
                    <img
                      className="slideee_imageegg d-block w-100"
                      src="/assets/images/hero_slide_1.webp"
                      alt="..."
                    />
                  </div>
                  <div className="carousel-item active" data-bs-interval="3000">
                    <img
                      className="slideee_imageegg d-block w-100"
                      src="/assets/images/hero_slide_2.webp"
                      alt="..."
                    />
                  </div>
                </Slider>
              </div>
            </div>
          </div>
          <img
            className="pt-img1 animate1"
            src="/assets/images/trangle-orange.png"
            alt=""
          />
          <img
            className="pt-img2 animate2"
            src="/assets/images/square-blue.png"
            alt=""
          />
          <img
            className="pt-img3 animate3"
            src="/assets/images/chicle-blue-2.png"
            alt=""
          />
          <img
            className="pt-img4 animate4"
            src="/assets/images/plus-orange.png"
            alt=""
          />

          <img
            className="pt-img5 animate-wave"
            src="/assets/images/wave-orange.png"
            alt=""
          />
        </div>

        {/* Cards */}
        <ul className="hov_cards741">
          <Link to="/products-single/Ointment">
            <li className="crddssabdc card-1">
              <h5 className="crd-cont41">Ointment</h5>
              <img
                className="hovimg_78"
                src="assets/images/allergy_1.png"
                alt=""
              />
            </li>
          </Link>
          <Link to="/products-single/Syrup">
            <li className="crddssabdc card-1">
              <h5 className="crd-cont41">Syrups</h5>
              <img
                className="hovimg_78"
                src="assets/images/syrup_d12.png"
                alt=""
              />
            </li>
          </Link>
          <Link to="/products-single/Injection">
            <li className="crddssabdc card-1">
              <h5 className="crd-cont41">Injection</h5>
              <img
                className="hovimg_78"
                src="assets/images/vaccine.png"
                alt=""
              />
            </li>
          </Link>
          <Link to="/products-single/Oil">
            <li className="crddssabdc card-1">
              <h5 className="crd-cont41">Oil</h5>
              <img
                className="hovimg_78"
                src="assets/images/drugs_s12.png"
                alt=""
              />
            </li>
          </Link>
          <Link to="/products-single/Tablets">
            <li className="crddssabdc card-1">
              <h5 className="crd-cont41">Tablets</h5>
              <img
                className="hovimg_78"
                src="assets/images/herbal.png"
                alt=""
              />
            </li>
          </Link>
          <Link to="/products-single/Ear-Eye-Drops">
            <li className="crddssabdc card-1">
              <h5 className="crd-cont41">Ear-Eye-Drops</h5>
              <img
                className="hovimg_78"
                src="assets/images/syringe.png"
                alt=""
              />
            </li>
          </Link>
          <Link to="/products-single/Gynaecare">
            <li className="crddssabdc card-1">
              <h5 className="crd-cont41">Gynaecare</h5>
              <img
                className="hovimg_78"
                src="assets/images/maternity.png"
                alt=""
              />
            </li>
          </Link>
          <Link to="/products-single/Orthopedic">
            <li className="crddssabdc card-1">
              <h5 className="crd-cont41">Orthopedic</h5>
              <img
                className="hovimg_78"
                src="assets/images/orthopedics.png"
                alt=""
              />
            </li>
          </Link>
        </ul>

        {/* --------------------------------------------// */}
        <div className="container mt-100">
          <div className="appointment-form-two">
            <div className="col-lg-6 col-sm-12 col-md-12">
              <div
                className="form-wrap d-flex align-items-center flex-column justify-content-center form-wrap-rj"
                style={{ position: "relative" }}
              >
                <div className="icons-rotating">
                  <div
                    className="ivon1-rj"
                    id="chng-click"
                    onClick={() => handleIconClick("Registered Markups")}
                  >
                    <i className="fa-solid fa-syringe" />
                  </div>

                  <div
                    className="ivon2-rj"
                    id="chng-click2"
                    onClick={() => handleIconClick("Registered Medicines")}
                  >
                    <i className="fa-solid fa-tablets" />
                  </div>
                  <div
                    className="ivon3-rj"
                    id="chng-click3"
                    onClick={() => handleIconClick("Registered Markups")}
                  >
                    <i className="fa-solid fa-hospital" />
                  </div>
                  <div
                    className="ivon4-rj"
                    id="chng-click4"
                    onClick={() => handleIconClick("Registered Medicines")}
                  >
                    <i className="fa-solid fa-house-chimney-medical" />
                  </div>
                  <div
                    className="ivon5-rj"
                    id="chng-click5"
                    onClick={() => handleIconClick("Registered Markups")}
                  >
                    <i className="fa-solid fa-capsules" />
                  </div>
                </div>

                {[1, 2, 3, 4, 5].map((index) => (
                  <div className="icons-cnt-rj" id="icon1-cnt" key={index}>
                    <h4 className="text-center" style={{ color: "#009cad" }}>
                      {activeIcon}
                    </h4>
                    <p className="text-center tim" style={{ color: "#343a40" }}>
                      {getContentForIcon(activeIcon)}
                    </p>
                  </div>
                ))}

                {/* <div
                className="icons-cnt-rj"
                style={{ color: "#009cad", display: "none" }}
                id="icon2-cnt"
              >
                <h4 className="text-center">{activeIcon}</h4>
                <p className="text-center tim" style={{ color: "#343a40" }}>
                  With their stakeholders, We because the best PCD Pharma
                  companies
                </p>
              </div> */}
                {/* <div
                className="icons-cnt-rj"
                style={{ color: "#009cad", display: "none" }}
                id="icon3-cnt"
              >
                <h4 className="text-center">{activeIcon}</h4>
                <p className="text-center tim" style={{ color: "#343a40" }}>
                  With their stakeholders, We because the best PCD Pharma
                  companies
                </p>
              </div> */}
                {/* <div
                className="icons-cnt-rj"
                style={{ color: "#009cad", display: "none" }}
                id="icon4-cnt"
              >
                <h4 className="text-center">{activeIcon}</h4>
                <p className="text-center tim" style={{ color: "#343a40" }}>
                  With their stakeholders, We because the best PCD Pharma
                  companies
                </p>
              </div> */}
                {/* <div
                className="icons-cnt-rj"
                style={{ color: "#009cad", display: "none" }}
                id="icon5-cnt"
              >
                <h4 className="text-center">{activeIcon}</h4>
                <p className="text-center tim" style={{ color: "#343a40" }}>
                  With their stakeholders, We because the best PCD Pharma
                  companies
                </p>
              </div> */}
              </div>
            </div>

            <div className="col-lg-6 col-sm-12 col-md-12">
              <div>
                <div className="section-heading mb-40">
                  <h2 className="title">
                    What Makes Us Your Trusted Pharma Franchise Company?
                  </h2>
                  <div className="uls-rj d-flex my-4">
                    <ul className="check-list mt-35 pr-xl-4">
                      <li
                        className="wow fadeInUp"
                        data-wow-delay="0.3s"
                        style={{
                          visibility: "visible",
                          animationdelay: "0.3s",
                        }}
                      >
                        <span className="pe-1 rgt-arr">
                          <i
                            className="fa-solid fa-square-check"
                            style={{ color: "#499afa" }}
                          />
                        </span>
                        Wide Range of products to cater different business needs
                        like retailers, wholesalers, etc.
                      </li>
                      <li
                        className="wow fadeInUp"
                        data-wow-delay="0.4s"
                        style={{
                          visibility: "visible",
                          animationdelay: "0.4s",
                        }}
                      >
                        <span className="pe-1 rgt-arr">
                          <i
                            className="fa-solid fa-square-check"
                            style={{ color: "#499afa" }}
                          />
                        </span>
                        Operating across PAN India.
                      </li>
                      <li
                        className="wow fadeInUp"
                        data-wow-delay="0.5s"
                        style={{
                          visibility: "visible",
                          animationdelay: "0.5s",
                        }}
                      >
                        <span className="pe-1 rgt-arr">
                          <i
                            className="fa-solid fa-square-check"
                            style={{ color: "#499afa" }}
                          />
                        </span>
                        ISO, GMP and WHO-certified medicinal products.
                      </li>
                      <li
                        className="wow fadeInUp"
                        data-wow-delay="0.6s"
                        style={{
                          visibility: "visible",
                          animationdelay: "0.6s",
                        }}
                      >
                        <span className="pe-1 rgt-arr">
                          <i
                            className="fa-solid fa-square-check"
                            style={{ color: "#499afa" }}
                          />
                        </span>
                        Exceptional support to our PCD franchise partners.
                      </li>
                    </ul>
                    <ul className="check-list mt-35 pr-xl-4">
                      <li
                        className="wow fadeInUp"
                        data-wow-delay="0.3s"
                        style={{
                          visibility: "visible",
                          animationdelay: "0.3s",
                        }}
                      >
                        <span className="pe-1 rgt-arr">
                          <i
                            className="fa-solid fa-square-check"
                            style={{ color: "#499afa" }}
                          />
                        </span>
                        Diverse distribution network.
                      </li>
                      <li
                        className="wow fadeInUp"
                        data-wow-delay="0.4s"
                        style={{
                          visibility: "visible",
                          animationdelay: "0.4s",
                        }}
                      >
                        <span className="pe-1 rgt-arr">
                          <i
                            className="fa-solid fa-square-check"
                            style={{ color: "#499afa" }}
                          />
                        </span>
                        Excellent branding and packaging.
                      </li>
                      {/* <li
                      className="wow fadeInUp"
                      data-wow-delay="0.5s"
                      style={{ visibility: "visible", animationDelay: "0.5s" }}
                    >
                      <span className="pe-1 rgt-arr">
                        <i
                          className="fa-solid fa-square-check"
                          style={{ color: "#499afa" }}
                        />
                      </span>
                      Ethical and quality work approach.

                    </li> */}
                      <li
                        className="wow fadeInUp"
                        data-wow-delay="0.6s"
                        style={{
                          visibility: "visible",
                          animationDelay: "0.6s",
                        }}
                      >
                        <span className="pe-1 rgt-arr">
                          <i
                            className="fa-solid fa-square-check"
                            style={{ color: "#499afa" }}
                          />
                        </span>
                        Ethical and quality work approach.
                      </li>
                    </ul>
                  </div>
                  <p className>
                  <a href="https://www.bioraiz.in/">Bioraiz Healthcare</a> was established with the goal of
                    providing excellent pharmaceutical products and having a
                    major influence on the healthcare sector. The healthcare
                    unit has become well-known throughout India because of its
                    strong dedication to quality and client satisfaction,
                    lending certified drugs to its valued <a href="https://orangebiotech.in/top-pharma-franchise-company-india/">pharma franchise </a>
                    partners, retailers, wholesalers, distributors and the whole
                    pharma community. Even though we concentrate on top-notch
                    research, we are happy that we have been contributing to
                    enhance healthcare standards and overall well being across
                    the country.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <section class="product_slidesec my-1">
          <div className="container borde text-center ">
            <h1>Our Products</h1>

            <div>
              <div className="product__sec_7 mt-3">
                <div className="slider_allimages1  ">
                  <Slider className="borde" {...settingsImg}>
                    <Link to="/Product-Listing">
                      <img
                        className="prod__uct_images"
                        src="/assets/images/image_21.webp"
                      />
                    </Link>
                    <Link to="/Product-Listing">
                      <img
                        className="prod__uct_images"
                        src="/assets/images/image_25.webp"
                      />
                    </Link>
                    <Link to="/Product-Listing">
                      <img
                        className="prod__uct_images"
                        src="/assets/images/image_23.webp"
                      />
                    </Link>
                    <Link to="/Product-Listing">
                      <img
                        className="prod__uct_images"
                        src="/assets/images/image_24.webp"
                      />
                    </Link>

                    <Link to="/Product-Listing">
                      <img
                        className="prod__uct_images"
                        src="/assets/images/image_23.webp"
                      />
                    </Link>
                    <Link to="/Product-Listing">
                      <img
                        className="prod__uct_images"
                        src="/assets/images/image_24.webp"
                      />
                    </Link>
                  </Slider>
                  <Link to="/Product-Listing">
                    <button class="slider_btn1">
                      Explore More{" "}
                      <i class="fa-solid fa-arrow-right arrow_right"></i>
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>

        <CountData />

        {/* counter end here  */}

        {/* About us */}

        <AboutHead />

        {/* Our Progress */}

        <section
          className="section-area section-sp5 work-area my-5"
          style={{
            backgroundImage: "url(/assets/images/background/line-bg1.png)",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundSize: "100%",
          }}
        >
          <div className="container-sm">
            <div className="heading-bx text-center">
              {/*<h6 class="title-ext text-secondary">About Bioraiz</h6>*/}
              <h2 className="title">Our Services</h2>
            </div>

            <div className="row justify-content-center">
              <div className="col-lg-4 col-sm-6 mb-30">
                <div className="work-bx">
                  <div className="work-num-bx">01</div>
                  <div className="work-content">
                    <h5 className="title text-secondary mb-10 slide_texttt_7">
                    Pharma franchise
                    </h5>
                    <p>
                    With the best marketing perks and wide range of medicines, get our <a href="https://www.bioraiz.in/pharma-franchise/">pharma franchise</a> and run a profitable venture.


                    </p>
                  </div>
                  <Link to="/pharma-franchise" className="btn btn-primary light">
                    View More <i className="btn-icon-bx fas fa-chevron-right" />
                  </Link>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6 mb-30">
                <div className="work-bx active">
                  <div className="work-num-bx">02</div>
                  <div className="work-content">
                    <h5 className="title text-secondary mb-10 ">
                      PCD Pharma Companies
                    </h5>
                    <p>
                    With a minimal investment and maximum benefits,
                     what else would you need to start a productive
                      business with Bioraiz Healthcare- one of the trusted PCD pharma companies.

                    </p>
                  </div>
                  <Link
                    to={"/pcd-pharma-companies"}
                    className="btn btn-primary light"
                  >
                    View More <i className="btn-icon-bx fas fa-chevron-right" />
                  </Link>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6 mb-30">
                <div className="work-bx">
                  <div className="work-num-bx">03</div>
                  <div className="work-content">
                    <h5 className="title text-secondary mb-10 slide_texttt_7">
                      Quality Assurance
                    </h5>
                    <p>
                    We have obtained multiple certifications, such as ISO 9001:2015, GMP,
                     and WHO-GMP, which makes us committed to
                     global healthcare standards
                    </p>
                  </div>
                  <Link to={"/"} className="btn btn-primary light">
                    View More <i className="btn-icon-bx fas fa-chevron-right" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <img
            className="pt-img1 animate1"
            src="/assets/images/circle-orange.png"
            alt=""
          />
          <img
            className="pt-img2 animate2"
            src="/assets/images/plus-orange.png"
            alt=""
          />
          <img
            className="pt-img3 animate3"
            src="/assets/images/circle-dots.png"
            alt=""
          />
        </section>

        {/* Appointment  */}

        <Calculator />
        {/* Testimonial  */}

        <Testimonial />

        {/* Blog  */}
        <VideoSlider />

        <section className="mt-5">
          <h2 style={{ textAlign: "center", marginBottom: "20px" }}>
            FAQ's &amp; Knowledgebase
          </h2>
          <div className="container py-2">
            <div className="row">
              <div className="col-12 col-md-6">
                <div
                  className="accordion accordion-flush accord__771"
                  id="accordionFlushExample"
                >
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="flush-headingOne">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseOne"
                        aria-expanded="false"
                        aria-controls="flush-collapseOne"
                      >
                        1. What Minimum investment is required for Pharma
                        Franchise?
                      </button>
                    </h2>
                    <div
                      id="flush-collapseOne"
                      className="accordion-collapse collapse"
                      aria-labelledby="flush-headingOne"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div className="accordion-body">
                        A <a href="https://biostempharma.com/pcd-pharma/">PCD Pharma</a> is a successful model as it has low risk
                        and high Returns. For taking up a reputed company’s
                        franchise minimum investment on an average would be
                        30,000. This involves the product purchase price and
                        promotional material. Minimum investment may vary a
                        little bit from company to company.
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="accordion accordion-flush accord__771"
                  id="accordionFlushExample"
                >
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="flush-headingTwo">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseTwo"
                        aria-expanded="false"
                        aria-controls="flush-collapseTwo"
                      >
                        2. How to choose a right PCD Pharma Company?
                      </button>
                    </h2>
                    <div
                      id="flush-collapseTwo"
                      className="accordion-collapse collapse"
                      aria-labelledby="flush-headingTwo"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div className="accordion-body">
                        Present market is filled with plethora of PCD options.
                        It may be confusing too. Choosing the right PCD involves
                        gathering information on which company provides the best
                        quality and efficacy products. Products should be
                        WHO/GMP/GLP approved. Trademark registration makes a
                        product more trust worthy. Try to find companies that
                        offer more than 1500+ product range and minimum of five
                        divisions.
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="accordion accordion-flush accord__771"
                  id="accordionFlushExample"
                >
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="flush-headingThree">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseThree"
                        aria-expanded="false"
                        aria-controls="flush-collapseThree"
                      >
                        3. What is WHO/GMP?
                      </button>
                    </h2>
                    <div
                      id="flush-collapseThree"
                      className="accordion-collapse collapse"
                      aria-labelledby="flush-headingThree"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div className="accordion-body">
                        WHO- world health organization is basically a global
                        organization that regulates global health matters,
                        setting norms and standards, regulating agendas. In
                        short, this organization regulates public health. WHO
                        certified products gives assurance that the products are
                        100 percent safe for consumption. GMP-goods
                        manufacturing practices are the steps followed by
                        manufacturing units. Factories make sure that they
                        follow the aseptic conditions and proper sanitation
                        during the manufacturing. It also ensures that the
                        products are impurity free and is manufactured as per
                        standards.
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="accordion accordion-flush accord__771"
                  id="accordionFlushExample"
                >
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="flush-headingFour">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseFour"
                        aria-expanded="false"
                        aria-controls="flush-collapseFour"
                      >
                        4. Promotional Material offered by Orange Biotech Pvt
                        Ltd?
                      </button>
                    </h2>
                    <div
                      id="flush-collapseFour"
                      className="accordion-collapse collapse"
                      aria-labelledby="flush-headingFour"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div className="accordion-body">
                        Promotional material is required for the proper
                        presentation of the products to the doctors and
                        healthcare providers. Following is the list of
                        promotional material offered by Fibovil pharmaceuticals
                        Pvt Ltd.
                        <ul className="main_acc__4">
                          <li className="ques_acc__4">Mr Bag</li>
                          <li className="ques_acc__4">Visual aid</li>
                          <li className="ques_acc__4">Lbl’s</li>
                          <li className="ques_acc__4">Reminders</li>
                          <li className="ques_acc__4">Product Cards</li>
                          <li className="ques_acc__4">NotePads</li>
                          <li className="ques_acc__4">Pens</li>
                          <li className="ques_acc__4">
                            Marketing gift inputs (paperweights, bottles, and
                            card holders)
                          </li>
                          <li className="ques_acc__4">Diary</li>
                          <li className="ques_acc__4">MR Uniform</li>
                          <li className="ques_acc__4">Visiting cards</li>
                          <li className="ques_acc__4">Catch covers</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="accordion accordion-flush accord__771"
                  id="accordionFlushExample"
                >
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="flush-headingFive">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseFive"
                        aria-expanded="false"
                        aria-controls="flush-collapseFive"
                      >
                        5. What is Monopoly Rights in a PCD Company?
                      </button>
                    </h2>
                    <div
                      id="flush-collapseFive"
                      className="accordion-collapse collapse"
                      aria-labelledby="flush-headingFive"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div className="accordion-body">
                        PCD Pharma is a business model which involves a
                        franchise agreement between the parent company and the
                        distributor/stockiest for a particular allotted area,
                        that is the buyer and exhibit and sell the therapeutics
                        in that allocated area only and none other can take up
                        the same franchise like the buyer in the same area. This
                        is called a monopoly right per area. Having monopoly
                        rights is beneficial as it increases your uniqueness and
                        overall revenue of the business.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div
                  className="accordion accordion-flush accord__771"
                  id="accordionFlushExample"
                >
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="flush-headingSix">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseSix"
                        aria-expanded="false"
                        aria-controls="flush-collapseSix"
                      >
                        6. What are PTR and PTS?
                      </button>
                    </h2>
                    <div
                      id="flush-collapseSix"
                      className="accordion-collapse collapse"
                      aria-labelledby="flush-headingSix"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div className="accordion-body">
                        PTR- it refers to price to retailer. There are
                        approximately fixed margins for a retailer that is
                        around 28.5% is the price at which goods are provided to
                        retailers. PTS- it refers to price to stockiest. There
                        are approximately fixed margins for a stockiest that is
                        around PTR-10% is the price at which goods are provided
                        to stockiest.
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="accordion accordion-flush accord__771"
                  id="accordionFlushExample"
                >
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="flush-headingSeven">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseSeven"
                        aria-expanded="false"
                        aria-controls="flush-collapseSeven"
                      >
                        7. What are COA reports?
                      </button>
                    </h2>
                    <div
                      id="flush-collapseSeven"
                      className="accordion-collapse collapse"
                      aria-labelledby="flush-headingSeven"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div className="accordion-body">
                        COA reports stand for certificate of analysis. It refers
                        to a lab certificate that gives complete information of
                        the quantity and purity of the molecules present in a
                        drug. Government standard reference index are also
                        provided on the report to check the quality and efficacy
                        of the product.
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="accordion accordion-flush accord__771"
                  id="accordionFlushExample"
                >
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="flush-headingEight">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseEight"
                        aria-expanded="false"
                        aria-controls="flush-collapseEight"
                      >
                        8. How is PCD different from generic and Ethical Pharma
                        Business?
                      </button>
                    </h2>
                    <div
                      id="flush-collapseEight"
                      className="accordion-collapse collapse"
                      aria-labelledby="flush-headingEight"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div className="accordion-body">
                        The major difference in both models of pharmaceutical
                        business is their work model. Ethical companies promote
                        their goods on the basis of their own field team whereas
                        in generic there is no field team involved &amp; their
                        business is based on volume market which may have
                        quality issues. PCD pharma prepare their distributor
                        network through franchise agreements, and they emphasize
                        more on quality over quantity.
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="accordion accordion-flush accord__771"
                  id="accordionFlushExample"
                >
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="flush-headingNine">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseNine"
                        aria-expanded="false"
                        aria-controls="flush-collapseNine"
                      >
                        9. Who can take a Pharma Franchise?
                      </button>
                    </h2>
                    <div
                      id="flush-collapseNine"
                      className="accordion-collapse collapse"
                      aria-labelledby="flush-headingNine"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div className="accordion-body">
                        For starting up a pharma franchise one need to have
                        knowledge and educational experience in therapeutics.
                        Proper documentation is required to get a PCD company’s
                        franchise. Drug license and GST certificate is one of
                        the major documents to start the process. Some
                        experience in this field is required to be successful in
                        pharma franchise business.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="container my-5">
            <h2 style={{ textAlign: "center", marginBottom: "20px" }}>
              Get in Touch for More Information
            </h2>
            <div className="row">
              <div className="col-12 col-md-6 px-2">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3445.766846768349!2d76.85307551080354!3d30.27222367470467!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390fb5e70000006b%3A0xce093e491a8b1ae8!2sOrange%20Biotech!5e0!3m2!1sen!2sin!4v1704278724423!5m2!1sen!2sin"
                  width="100%"
                  height="100%"
                  style={{ border: 0 }}
                  allowFullScreen
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                />
              </div>
              <div className="col-12 col-md-6 px-2 mb-30 mt-3">
                <form
                  className="form-wraper contact-form ajax-form"
                  action="script/contact.php"
                >
                  <div className="ajax-message" />
                  <div className="row">
                    <div className="form-group col-md-6">
                      <input
                        name="name"
                        type="text"
                        required
                        className="form-control"
                        placeholder="Your Name"
                      />
                    </div>
                    <div className="form-group col-md-6">
                      <input
                        name="email"
                        type="email"
                        required
                        className="form-control"
                        placeholder="Email"
                      />
                    </div>
                    <div className="form-group col-md-6">
                      <input
                        name="phone"
                        type="text"
                        required
                        className="form-control"
                        placeholder="Phone Numbers"
                      />
                    </div>
                    <div className="form-group col-md-6">
                      <div className="dropdown bootstrap-select form-select">
                        <select className="form-select">
                          <option selected>Selecty Department</option>
                          <option value={1}>One</option>
                          <option value={2}>Two</option>
                          <option value={3}>Three</option>
                        </select>
                        <div className="dropdown-menu ">
                          <div
                            className="inner show"
                            role="listbox"
                            id="bs-select-1"
                            tabIndex={-1}
                          >
                            <ul
                              className="dropdown-menu inner show"
                              role="presentation"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="form-group col-md-12">
                      <textarea
                        name="message"
                        required
                        className="form-control"
                        placeholder="Type Message"
                        defaultValue={""}
                      />
                    </div>
                    <div className="form-group col-md-12">
                      <div className="input-group"></div>
                    </div>
                    <div className="col-lg-12">
                      <button
                        name="submit"
                        type="submit"
                        value="Submit"
                        className="btn w-100 btn-secondary btn-lg"
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>

        {/* footer */}
      </div>
    </>
  );
};

export default HomePage;
