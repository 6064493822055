import React, { useState, useEffect } from "react";
import { parseISO, format } from "date-fns";
import "rsuite/dist/rsuite.min.css";
import { Pagination } from "rsuite/";
import { Link } from "react-router-dom";
import axios from "axios";
import { LazyLoadImage } from "react-lazy-load-image-component";
import he from "he";
import LoaderDiv from "../components/LoaderDiv";
import Seo from "../components/Seo";
const BlogPage = () => {
  const [blogData, setBlogData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [activePage, setActivePage] = useState(1); // Current active page
  const [totalPages, setTotalPages] = useState(0); // Total number of pages
  const itemsPerPage = 6;
  const getData = async () => {
    // setLoading(true);
    try {
      const res = await axios.get(
        "https://bioraiz.in/bioraiz_admin/api/get_blog"
      );
      setBlogData(res.data);
      //console.log(res.data,'blog page data')
      setLoading(false);

      const totalPagesCount = Math.ceil(res?.data?.length / itemsPerPage);
      setTotalPages(totalPagesCount);
    } catch (error) {
      console.log(error);
    }
  };

  const handlePageChange = (page) => {
    setActivePage(page);
  };
  useEffect(() => {
    getData();
    setLoading(true);
  }, [activePage]);
  // console.log(blogData, "blog data");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Seo
        title="Latest Pharma Blogs | Pharma Franchise blogs - Bioraiz Healthcare"
        description="Providing best quality of  products and services to our customers."
        type="webapp"
        name="Bioraiz Pharma"
      />
      <section>
        <div className="container-fluid mt-5 p-0">
          <div className="bg_cont__45__products ban__nerrr">
            <h1 style={{ color: "#fff", zIndex: "99" }}>Our Blogs</h1>
          </div>
        </div>
        <section className="section-area section-sp1">
          <div className="container">
            <div className="row py-5">
              {loading ? (
                <div className="d-flex justify-content-center">
                  {/* {/ <BeatLoader color="#007BFF" loading={loading} size={15} /> /} */}
                  <LoaderDiv />
                  {/* {/ <span>Loading...</span> /} */}
                </div>
              ) : (
                blogData.length &&
                blogData
                  .slice(
                    (activePage - 1) * itemsPerPage,
                    activePage * itemsPerPage
                  )
                  .map((item) => {
                    // console.log(item,"kshubjj")
                    const dateObject = parseISO(item?.created_at);
                    const formattedDate = format(dateObject, "MMM dd, yyyy");
                    return (
                      <div className="col-xl-4 col-md-6" key={item.id}>
                        <div className="blog-card mb-30">
                          <div className="post-media">
                            <Link to={`/${item.slug}/`}>
                              <LazyLoadImage
                                src={item?.image}
                                alt={item?.image}
                              />
                            </Link>
                          </div>
                          <div className="post-info">
                            <ul className="post-meta">
                              <li className="author">
                                <LazyLoadImage
                                  src={item?.image}
                                  alt={item?.image}
                                />{" "}
                                {item?.category}
                              </li>
                              <li className="date">
                                <i className="far fa-calendar-alt fs-6" />
                                {formattedDate}
                              </li>
                            </ul>
                            <h4 className="post-title">
                              <Link
                                to={`/${item.slug}/`}
                                className="one-line-text"
                              >
                                {item?.title}
                              </Link>
                            </h4>
                            <p className="blog_desc_1 my-md-2">
                              {item?.description ? (
                                <span
                                  className="one-line-text"
                                  dangerouslySetInnerHTML={{
                                    __html: he.decode(
                                      item.description.length > 35
                                        ? item.description.slice(0, 35) + "..."
                                        : item.description
                                    ),
                                  }}
                                />
                              ) : (
                                "No description available"
                              )}
                            </p>
                            <Link
                              to={`/${item.slug}/`}
                              className="btn btn-outline-primary btn-sm"
                            >
                              Read More{" "}
                              <i className="btn-icon-bx fas fa-chevron-right" />
                            </Link>
                          </div>
                        </div>
                      </div>
                    );
                  })
              )}
            </div>

            <div className="row">
              <div className="col-lg-12 d-flex justify-content-center">
                <div className="">
                  <Pagination
                    prev
                    last
                    next
                    first
                    size="lg"
                    total={totalPages}
                    activePage={activePage}
                    onSelect={handlePageChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* {/ Footer ==== /} */}

        {/* {/ Footer END ==== /} */}
        <button
          className="back-to-top fa fa-chevron-up"
          style={{ display: "none" }}
        />
      </section>
    </div>
  );
};

export default BlogPage;
