import React,{useEffect, useState} from 'react'
import axios from 'axios';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Seo from '../components/Seo';

function ProductListing() {
  const [prdctList, setPrdctList] = useState([]);
  const getProdct = async()=>{
    try{
      const res= await axios.get('https://bioraiz.in/bioraiz_admin/api/get_listing');
      setPrdctList(res.data)

    }
    catch(err){console.log('error' + err)};
  }
  useEffect(() => {
    window.scrollTo(0, 0);
    getProdct()
  }, []);
 // console.log("Product List", prdctList);
  return (

      <section id="pro_duct12list">
        <Seo
        title={`Top Quality Products | WHO-ISO Certified | Bioraiz Healthcare`}
        description="Bioraiz Healthcare provide WHO-ISO certified high-quality pharma products all over in India. We have more than 500+ Produts in all ranges. Call Now!"
        type="webapp"
        name="Bioraiz Pharma"
      />
          <div className="container mt-5">
              <h3 className='pro_ducthead'>Featured Products</h3>
            <div className="row my-5 d-flex justify-content-cente">
              {
                prdctList.map((item,index)=>{
                  // console.log(item)
                  return(
                    <div className="col-3 col-lg-3 col-sm-12" key={index}>
                  <LazyLoadImage className='product_img1' src={item.image} alt="image" />
              </div>

                  )
                })
              }
              {/* <div className="col-3 col-lg-3 col-sm-12 ">
                  <img className='product_img1' src={'./assets/images/image_1.jpg'} alt="image" />
              </div> */}

              {/* <div className="col-3 col-lg-3  col-sm-12">
              <img className='product_img1' src={'./assets/images/image_9.jpg'} alt="image" />
              </div>

              <div className="col-3 col-lg-3  col-sm-12">
              <img className='product_img1' src={'./assets/images/image_31.jpg'} alt="image" />
              </div>

              <div className="col-3 col-lg-3  col-sm-12">
               <img className='product_img1' src={'./assets/images/image_35.jpg'} alt="image" />
               </div>
               <div className="col-3 col-lg-3 col-sm-12 ">
                  <img className='product_img1' src={'./assets/images/image_1.jpg'} alt="image" />
              </div>

              <div className="col-3 col-lg-3  col-sm-12">
              <img className='product_img1' src={'./assets/images/image_9.jpg'} alt="image" />
              </div>

              <div className="col-3 col-lg-3  col-sm-12">
              <img className='product_img1' src={'./assets/images/image_31.jpg'} alt="image" />
              </div>

              <div className="col-3 col-lg-3  col-sm-12">
               <img className='product_img1' src={'./assets/images/image_35.jpg'} alt="image" />
               </div>
               <div className="col-3 col-lg-3 col-sm-12 ">
                  <img className='product_img1' src={'./assets/images/image_1.jpg'} alt="image" />
              </div>

              <div className="col-3 col-lg-3  col-sm-12">
              <img className='product_img1' src={'./assets/images/image_9.jpg'} alt="image" />
              </div>

              <div className="col-3 col-lg-3  col-sm-12">
              <img className='product_img1' src={'./assets/images/image_31.jpg'} alt="image" />
              </div>

              <div className="col-3 col-lg-3  col-sm-12">
               <img className='product_img1' src={'./assets/images/image_35.jpg'} alt="image" />
               </div> */}
            </div>
          </div>
      </section>
    
  )
}

export default ProductListing