// AboutPage.js
import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import Seo from "../components/Seo";
import CountUp from "react-countup";
import Aboutnew from "../components/Aboutnew";

const AboutPage = () => {
  const handleSearchToggle = () => {
    // Implement your search toggle logic here
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Seo
        title="About us - Bioraiz Healthcare | Top PCD Pharma Company"
        description="About Bioraiz Healthcare is one of fastest growing Pharmaceutical Company in India offering products on Pharma franchise with monopoly rights."
        type="webapp"
        name="Bioraiz Pharma"
      />
      <section>
        <div className="container-fluid mt-5 p-0">
          <div className="bg_cont__45__ab__out ban__nerrr">
            <h1 style={{ color: "#fff", zIndex: "99" }}>About Us</h1>
          </div>
        </div>
        {/* About us */}
        <Aboutnew />
      </section>

      <div>
        <section>
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-6 col-sm-12">
                <h2>
                Top PCD Pharma Company in India: Bioraiz
                  Healthcare!
                </h2>
                <p>
                  With a wealth of experience, <a href="https://www.bioraiz.in/"> Bioraiz Healthcare</a> is the top
                  <a href="https://www.bioraiz.in/pharma-franchise/">pharma franchise</a>, well-known for its precise
                  composition and efficient formulation. We are creating a wide
                  variety of reasonably priced medications. With the goal of
                  achieving global healthcare through medical products, we are
                  trying to be the best in the business and maintain our
                  position as an industry leader.
                </p>
                <p>
                  Having worked in the <a href="https://en.wikipedia.org/wiki/Pharmaceutical_industry">pharmaceutical industry</a> for many years,
                  we have a wide network of trusted vendors and manufacturers.
                  We guarantee that we provide our customers with only the best
                  drugs thanks to our extensive industry experience and strict
                  quality control procedures.
                </p>
                <br />
              </div>
              <div className="col-12 col-md-6 col-sm-12 image__sec__5">
                <img
                  className="abt__img__gg floating_about"
                  src="/assets/images/childddd_4.webp"
                />
                <img
                  className="abt__img__gg22 floating_about_2"
                  src="/assets/images/familyyyy_7.webp"
                />
              </div>
            </div>
          </div>
        </section>
        <section className="section-sp1 service-wraper2 mt-5">
          <div className="container">
            <div className="row">
              <div className="col-xl-3 col-sm-6 mb-30 mt-5">
                <div className="feature-container feature-bx3">
                  <h2 className="counter text-secondary">
                    <CountUp end={12} enableScrollSpy={true} />
                  </h2>
                  <h5 className="ttr-title">Years With You</h5>
                  <p>
                    Our extensive experience gives you expertise and network all
                    free.
                  </p>
                </div>
              </div>
              <div className="col-xl-3 col-sm-6 mb-30">
                <div className="feature-container feature-bx3">
                  <h2 className="counter text-secondary">
                    {" "}
                    <CountUp  end={30} enableScrollSpy={true}/>
                  </h2>
                  <h5 className="ttr-title">Employees</h5>
                  <p>
                  Our awards reward you to work with a recognized pharma unit.

                  </p>
                </div>
              </div>
              <div className="col-xl-3 col-sm-6 mb-30 mt-5">
                <div className="feature-container feature-bx3">
                  <h2 className="counter text-secondary">
                    {" "}
                    <CountUp end={8} enableScrollSpy={true} />
                  </h2>
                  <h5 className="ttr-title">Divisions</h5>
                  <p>
                  With a wide network of doctors we offer sales and profitability in advance.
                  </p>
                </div>
              </div>
              <div className="col-xl-3 col-sm-6 mb-30">
                <div className="feature-container feature-bx3">
                  <h2 className="counter text-secondary">
                    {" "}
                    <CountUp enableScrollSpy={true} end={150} />
                  </h2>
                  <h5 className="ttr-title">Satisfied Client</h5>
                  <p>
                  Our satisfied consumer base provides you repetitive customers and revenue.

                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      {/* footer */}

      <div>
        {/* Footer END ==== */}
        <button
          className="back-to-top fa fa-chevron-up"
          style={{ display: "none" }}
        />
      </div>
    </div>
  );
};

export default AboutPage;
